<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Edit Products</h3>
    </div>

    <!-- Content Row -->
    <MerchantProductForm :product-id="$route.params.id"></MerchantProductForm>

  </div>
</template>

<script>
import MerchantProductForm from "./MerchantProductForm";
export default {
  name: "EditMerchantProduct",
  components: {MerchantProductForm},
}
</script>

<style scoped>

</style>